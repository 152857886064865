import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user.service";
import {ServerResponse} from "../../models/server-response.model";
import {USER_DATA_FILE_PATH} from "../../utils/constant";
import { MatDialog } from '@angular/material/dialog';
import { WizardConfirmSchoolDataComponent } from '../wizard-confirm-school-data/wizard-confirm-school-data.component';

@Component({
  selector: 'app-btn-upload-school-data',
  templateUrl: './btn-upload-school-data.component.html',
  styleUrls: ['./btn-upload-school-data.component.css']
})
export class BtnUploadSchoolDataComponent implements OnInit {
  fileLink: '#';
  constructor(private userService: UserService, public dialog: MatDialog) {}

  ngOnInit() {}

  actionConfirmSchoolDataFile() {
    const dialogRef = this.dialog.open(WizardConfirmSchoolDataComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle the case when Yes is clicked
        console.log('User confirmed');
      } else {
        // Handle the case when No is clicked
        console.log('User canceled');
      }
    });
  }

}
