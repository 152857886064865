import { Component, Input } from '@angular/core';

@Component({
  selector: 'btn-csv-preview',
  templateUrl: './btn-csv-preview.component.html',
  styleUrls: ['./btn-csv-preview.component.css']
})

export class BtnCsvPreviewComponent {
  @Input() file: File | null = null; // Input property for the file
  csvData: any[] = []; 

  previewCSV() {
    if (this.file && this.file.type === 'text/csv') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const text = e.target.result;
        this.csvData = this.parseCSV(text);
      };
      reader.readAsText(this.file);
    } else {
      alert('No valid CSV file selected.');
    }
  }
  
  parseCSV(text: string): any[] {
    const rows = text.split('\n');
    return rows.map(row => row.split(','));
  }
}
