import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user.service";
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {DataService} from '../../services/data.service';
import { ServerResponse } from 'src/app/models/server-response.model';

@Component({
  selector: 'app-wizard-confirm-school-data',
  templateUrl: './wizard-confirm-school-data.component.html',
  styleUrls: ['./wizard-confirm-school-data.component.css']
})
export class WizardConfirmSchoolDataComponent implements OnInit {
  selectedFile: File | null = null; // To hold the selected file
  errorMessage: string | null = null; // To hold error messages

  constructor(private userService: UserService, private data: DataService, public dialogRef: MatDialogRef<WizardConfirmSchoolDataComponent>) {}

  ngOnInit() {}

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        this.selectedFile = file;
        this.errorMessage = null; // Clear any previous error message
      } else {
        this.errorMessage = 'Please select a valid CSV file.';
        this.selectedFile = null; // Reset selected file
      }
    }
  }

  onActionUploadFile() {
    if (this.selectedFile) {
      console.log("File upload confirmed:", this.selectedFile.name);
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      
      this.data.uploadSchoolFile(formData).subscribe((response: ServerResponse) => {
        if (response.error === 0 && response.authError === 0) {
          swal({
            title: 'Message',
            text: 'File Upload Successfully',
            closeOnClickOutside : false,
            icon: 'success'
          });
          this.dialogRef.close(true); // Close dialog and return true

        } else if (response.error > 0) {
          swal({
                title: 'Message',
                text: response.msg,
                closeOnClickOutside : false,
                icon: 'warning'
          });
        }
      });
    } else {
      this.errorMessage = 'No file selected or file is invalid.';
      swal({
        title: 'Message',
        text: this.errorMessage,
        closeOnClickOutside : false,
        icon: 'warning'
      });
    }
  }

  onActionCancel(){
    this.dialogRef.close(false);
  }

}
