import {Component, OnInit, Input} from '@angular/core';
import {UserService} from "../../services/user.service";
import {ServerResponse} from "../../models/server-response.model";
import {USER_DATA_FILE_PATH} from "../../utils/constant";

@Component({
  selector: 'app-btn-download-school-data',
  templateUrl: './btn-download-school-data.component.html',
  styleUrls: ['./btn-download-school-data.component.css']
})

export class BtnDownloadSchoolDataComponent implements OnInit {

  @Input() fileLink: string;

  constructor(private userService: UserService) {
  }

  ngOnInit() {}

  downloadFile(event){

  }
}
