import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Api, Route, Token} from '../utils/constant';
import {ServerResponse} from '../models/server-response.model';

@Injectable()
export class DataService {

  constructor(private http: HttpClient) {
  }
  
  ///////////////////////////
  
  postresetUserPassword(id){
    return this.http.post(Api.resetUserPassword, {'userId': id});
  }

  putuserroleUser(data){
    return this.http.put(Api.userroleUser, data);
  }

  getUsers(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.userList);
  }
  
  activeUser(id) {
    return this.http.put(Api.user, id);
  }

  deactiveUser(id) {
    return this.http.request('delete', Api.user, {body: id});
  }
  
  cancelUserSubcription(id) {
    return this.http.request('delete', Api.actCancelUserSub, {body: id});
  }
  
  activeUserSubcription(id) {
    return this.http.put(Api.actCancelUserSub, id);
  }
  extendUserSubcription(data) {
    return this.http.put(Api.extendUserSub, data);
  }
  
  ////////////////////////////
  
  getUserRole(userroleid) {
    return this.http.get(Api.userrole + '?' + 'id=' + userroleid);
  }
  
  getUserRoles(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.userroleList);
  }
  
  postUserRole(userRoleModel) {
    return this.http.post(Api.userrole, userRoleModel);
  }
  
  updateUserRole(userRoleModel) {
    return this.http.put(Api.userrole, userRoleModel);
  }
  
  deleteUserRole(id) {
    return this.http.request('delete', Api.userrole, {body: id});
  }

  loadStaticUserRole(){
    return this.http.put(Api.userRoleLoad, {});
  }

  ///////////////////////////

  ////////////////////////////
  
  getUserModules() {
    return this.http.get(Api.moduleUserList);
  }

  getModule(moduleid) {
    return this.http.get(Api.module + '?' + 'id=' + moduleid);
  }
  
  getModules(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.moduleList);
  }
  
  postModule(moduleModel) {
    return this.http.post(Api.module, moduleModel);
  }
  
  updateModule(moduleModel) {
    return this.http.put(Api.module, moduleModel);
  }
  
  deleteModule(id) {
    return this.http.request('delete', Api.module, {body: id});
  }

  loadStaticModule() {
    return this.http.put(Api.moduleLoad, {});
  }

  ///////////////////////////

  ////////////////////////////
  
  getAnnouncement(announcementid) {
    return this.http.get(Api.announcement + '?' + 'id=' + announcementid);
  }
  
  getAnnouncements(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.announcementList);
  }
  
  postAnnouncement(anncModel) {
    return this.http.post(Api.announcement, anncModel);
  }
  
  updateAnnouncement(anncModel) {
    return this.http.put(Api.announcement, anncModel);
  }
  
  deleteAnnouncement(id) {
    return this.http.request('delete', Api.announcement, {body: id});
  }
  ///////////////////////////

  getStory(storyid) {
    return this.http.get(Api.story + '?' + 'id=' + storyid);
  }

  getStories(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.storyList);
  }

  postStory(storyModel) {
    return this.http.post(Api.story, storyModel);
  }

  deleteStory(id) {
    return this.http.request('delete', Api.story, {body: id});
  }

  updateStory(storyModel) {
    return this.http.put(Api.story, storyModel);
  }

  publishStory(id) {
    return this.http.post(Api.publishStory, id);
  }

  unPublishStory(id) {
    return this.http.request('delete', Api.publishStory, {body: id});
  }

  postFile(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(Api.postFile, formData);
  }

  putTutorial(tutorialUrl) {
    return this.http.put(Api.tutorial, tutorialUrl);
  }
  putTermsAndConditions(termsText) {
    return this.http.put(Api.termsAndConditions, termsText);
  }
  getTutorial(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.tutorial);
  }
  getTermsAndConditions(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.termsAndConditions);
  }
  getCategory(categoryId) {
    return this.http.get(Api.category + '?' + 'id=' + categoryId);
  }
  getSchool(schoolId) {
    return this.http.get(Api.organization + '?' + 'id=' + schoolId);
  }
  getLandinPageDetail(schoolTitle) {
    return this.http.get(Api.landingPage + '?' + 'url=' + schoolTitle);
  }

  postCategory(categoryModel) {
    return this.http.post(Api.category, categoryModel);
  }

  getCategories(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.categoryList);
  }

  updateCategory(categoryModel) {
    return this.http.put(Api.category, categoryModel);
  }
  publishCategory(id) {
    return this.http.post(Api.publishCategory, id);
  }

  unPublishCategory(id) {
    return this.http.request('delete', Api.publishCategory, {body: id});
  }
  getSchools(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.organizationList);
  }

  postSchool(schoolModel) {
    return this.http.post(Api.organization, schoolModel);
  }
  postsignup(signpModel) {
    return this.http.post(Api.signup, signpModel);
  }
  postrecipt(signupModel) {
    console.log("recipt model",signupModel);
    let recipt = {signupModel};
    console.log("recipt",recipt);
    return this.http.post(Api.recipt, {recipt:signupModel});
  }

  deleteCategory(id) {
    return this.http.request('delete', Api.category, {body: id});
  }
  deleteSchool(id) {
    return this.http.request('delete', Api.organization, {body: id});
  }


  updateSchool(schoolModel) {
    return this.http.put(Api.organization, schoolModel);
  }

  // Schools & Related APIs

  uploadSchoolFile(fileData) {
    return this.http.post(Api.schoolUploadFile, fileData);
  }

  getSchoolsList(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.schoolsList);
  }

  getSchoolsById(schoolsId) {
    return this.http.get(Api.schools + '?' + 'id=' + schoolsId);
  }

  postSchools(schoolsModel){
    return this.http.post(Api.schools, schoolsModel);
  }
  updateSchools(schoolsModel) {
    return this.http.put(Api.schools, schoolsModel);
  }

  deleteSchools(id) {
    return this.http.request('delete', Api.schools, {body: id});
  }
  updateSchoolsStatusbyId(body) {
    return this.http.put(Api.updateSchoolsState, body);
  }

  // Teachers & Related APIs

  getTeachersList(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.teachersList);
  }

  getStudentsList(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(Api.studentsList);
  }

}
