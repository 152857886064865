import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/services/form.service';
import { schools } from 'src/app/models/schools';
import swal from 'sweetalert';
import { ServerResponse } from 'src/app/models/server-response.model';
import {isObject} from "util";

@Component({
  selector: 'app-add-schools',
  templateUrl: './add-schools.component.html',
  styleUrls: ['./add-schools.component.css'] 
})
export class AddSchoolsComponent implements OnInit {

  _formErrors: any = {};
  form: FormGroup;
  schoolsModel:schools; 
  schools$: Object;
  counter = 0;
  
  responsemessage='';

  @ViewChild('schoolId') schoolIdField: ElementRef;
  @ViewChild('name') nameField: ElementRef;
  @ViewChild('principleName') principleNameField: ElementRef;
  @ViewChild('email') emailField: ElementRef;
  @ViewChild('phone') phoneField: ElementRef;
  @ViewChild('url') urlField: ElementRef;

  constructor(private titlecase: TitleCasePipe, 
              private data: DataService, 
              private route: ActivatedRoute, 
              private formService: FormService, 
              private router: Router) 
              {
                  this.route.params.subscribe(params => this.schools$ = params.id);
              }


  ngOnInit() { 
    this._resetFormErrors();
    this.form = new FormGroup({
      schoolId: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      principleName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [Validators.required,Validators.pattern('[0-9]+'),Validators.minLength(10)]),
      url: new FormControl(null, []),
    });
    this.initializeImage();
  }

  private _resetFormErrors(): void {
    for (const credentialKey in this.schoolsModel) {
      if (this.schoolsModel.hasOwnProperty(credentialKey)) {
        this._formErrors[credentialKey] = {valid: true, message: ''};
      }
    }
  }
  

  formReset() {
    this.router.navigate(['/' + Route.schools]);
  }


  hasError(field): boolean {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      let msg = '';
      if (this.form.get(field).errors.required) {
        msg = this.titlecase.transform(field.replace('_', ' ')) + ' cannot be blank.';
      } if (this.form.get(field).errors.email) {
        msg = 'Email is invalid.';
      }
      if (this.form.get(field).errors.minlength) {
        msg = 'Phone number should not be less than 10 character';
      }
       if (this.form.get(field).errors.pattern) {
         if(field=='phone')
         {
          msg ='Phone is not valid use only number' ;
         }
      } 
      else if (this.form.get(field).errors.server) {
        msg = this.form.get(field).errors.msg;
      }
      this._formErrors[field] = {valid: false, message: msg};
    } else {
      this._formErrors[field] = {valid: true, message: ''};
    }
    return !this._formErrors[field].valid;
  }

  OnSubmit() {
    if (!this.form.invalid) {
      this.schoolsModel.email = this.schoolsModel.email.toLowerCase();
      this.schoolsModel.active = 1;
      this.data.postSchools(this.schoolsModel).subscribe((response: ServerResponse) => {
          console.log('response', response);
          this.responsemessage = response.msg;
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'School is Added Successfully',
              closeOnClickOutside : false,
              icon: 'success'
            });
            this.router.navigate(['/' + Route.schools]).catch(reason => {
              console.log('reason', reason);
              this.initializeImage();
              this.formReset();
            });
          } else if (response.error > 0) {
			  swal({
              title: 'Message',
              text: response.msg,
              closeOnClickOutside : false,
              icon: 'warning'
            });
            if (isObject(response.msg)) {
              
              for (const msgKey in response.msg) {
                if (response.msg.hasOwnProperty(msgKey)) {
                  this.form.get(msgKey).setErrors({server: true, msg: response.msg[msgKey][0]});
                }
              }
            } else {
              // this.form.get('thumbnail').setErrors({server: true, msg: response.msg});
            }
          }
        },
        error => {
          console.log('error meassge ', error.message);
        });
    } else {
      this.formService.validateAllFormFields(this.form);
      if (this.hasError('name')) {
        this.nameField.nativeElement.focus();
      } else {
        console.log('error meassge ');
      }
      return false;
    }
  }

  initializeImage() {
    this.counter = 0;
    this.schoolsModel = new schools();
  }
}
