import { Component, OnInit, ViewChild} from '@angular/core';
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {DataService} from '../../services/data.service';
import { MatSort, MatTableDataSource ,MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-teacher',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.css']
})

export class TeachersComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource;
  displayColumns = ['id', 'school', 'firstName', 'lastName', 'email', 'phone', 'active', 'isSent', 'isFailed', 'reason'];
  appRouts = Route;
  searchFor: string;
  SERVER_IMAGE_PATH= SERVER_IMAGE_PATH;

  constructor(private data: DataService,private user: UserService, private router: Router) {}

  ngOnInit() {
    this.data.getTeachersList().subscribe(data => {
      if (data.error === 0 && data.authError === 0) {
        if (!data) {
          return;
        }
        this.dataSource = new MatTableDataSource(data.data.teachersList);
		    this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else if (data.error === 1 && data.authError === 1) {
          localStorage.clear();
          this.router.navigate(['/' + Route.login]);
        }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  removeFilter() {
    this.dataSource.filter = null;
    this.searchFor = '';
  }
  
}
