import {Routes} from '@angular/router';
import {AuthGuard, AnonymousAuthGuard, AppUserAuthGuard} from '../auth/auth.guard';
import {Route} from '../utils/constant';
import {ForgotPasswordComponent} from '../components/user/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from '../components/user/reset-password/reset-password.component';
import {StoriesComponent} from '../components/stories/stories.component';
import {SignInComponent} from '../components/user/sign-in/sign-in.component';
import {PageNotFoundComponent} from '../components/common/page-not-found/page-not-found.component';
import {PageComponent} from '../components/page/page.component';
import {HomeComponent} from '../components/home/home.component';
import {TypographyComponent} from '../components/typography/typography.component';
import {IconsComponent} from '../components/icons/icons.component';
import {MapsComponent} from '../components/maps/maps.component';
import {NotificationsComponent} from '../components/notifications/notifications.component';
import {UpgradeComponent} from '../components/upgrade/upgrade.component';
import {AddstoryComponent} from '../components/addstory/addstory.component';
import {DeletestoryComponent} from '../components/deletestory/deletestory.component';
import {DetailComponent} from '../components/detail/detail.component';
import {ChangePasswordComponent} from '../components/user/change-password/change-password.component';
import {CategoryComponent} from '../components/category/category.component';
import {AddCategoryComponent} from '../components/add-category/add-category.component';
import {EditCategoryComponent} from '../components/edit-category/edit-category.component';
import {DeleteCategoryComponent} from '../components/delete-category/delete-category.component';
import {AnalyticsKeywordSearchComponent} from "../components/analytics-keyword-search/analytics-keyword-search.component";
import {SchoolComponent} from '../components/school/school.component';
import {AddSchoolComponent} from '../components/add-school/add-school.component';
import {EditSchoolComponent} from '../components/edit-school/edit-school.component';
import {DeleteSchoolComponent} from '../components/delete-school/delete-school.component';
import {LandingPageComponent} from '../components/landing-page/landing-page.component';
import {AnnouncementComponent} from '../components/announcement/announcement.component';
import {AddAnnouncementComponent} from '../components/add-announcement/add-announcement.component';
import {EditAnnouncementComponent} from '../components/edit-announcement/edit-announcement.component';
import {ModuleComponent} from '../components/module/module.component';
import {AddModuleComponent} from '../components/add-module/add-module.component';
import {EditModuleComponent} from '../components/edit-module/edit-module.component';
import {UserRoleComponent} from '../components/userrole/userrole.component';
import {AddUserRoleComponent} from '../components/add-userrole/add-userrole.component';
import {EditUserRoleComponent} from '../components/edit-userrole/edit-userrole.component';
import {EditUserPermissionComponent} from '../components/edit-user-permission/edit-user-permission.component';
import {NewsLetterComponent} from '../components/news-letter/news-letter.component';
import {UsersPageComponent} from '../components/users-page/users-page.component';
import {SchoolsComponent} from '../components/schools/schools.component';
import {AddSchoolsComponent} from '../components/add-schools/add-schools.component';
import {EditSchoolsComponent} from '../components/edit-schools/edit-schools.component';
import {DeleteSchoolsComponent} from '../components/delete-schools/delete-schools.component';
import {TeachersComponent} from '../components/teachers/teachers.component';
import {StudentsComponent} from '../components/students/students.component';


export const appRoutes: Routes = [
  {path: Route.default, component: StoriesComponent, canActivate: [AuthGuard]},
  {path: Route.stories, component: StoriesComponent, canActivate: [AuthGuard]},
  {path: Route.login, component: SignInComponent, canActivate: [AnonymousAuthGuard]},
  {path: Route.forgotPassword, component: ForgotPasswordComponent, canActivate: [AppUserAuthGuard]},
  {path: Route.resetPassword, component: ResetPasswordComponent, canActivate: [AppUserAuthGuard]},
  {path: Route.home, component: HomeComponent, canActivate: [AuthGuard]},
  {path: Route.typography, component: TypographyComponent, canActivate: [AuthGuard]},
  {path: Route.categories, component: CategoryComponent, canActivate: [AuthGuard]},
  {path: Route.icons, component: IconsComponent, canActivate: [AuthGuard]},
  {path: Route.maps, component: MapsComponent, canActivate: [AuthGuard]},
  {path: Route.notifications, component: NotificationsComponent , canActivate: [AuthGuard]},
  {path: Route.upgrade, component: UpgradeComponent , canActivate: [AuthGuard]},
  {path: Route.detail, component: DetailComponent, canActivate: [AuthGuard]},
  {path: Route.addstory, component: AddstoryComponent, canActivate: [AuthGuard]},
  {path: Route.deletestory, component: DeletestoryComponent, canActivate: [AuthGuard]},
  {path: Route.addcategory, component: AddCategoryComponent, canActivate: [AuthGuard]},
  {path: Route.categorydetail, component: EditCategoryComponent, canActivate: [AuthGuard]},
  {path: Route.deletecategory, component: DeleteCategoryComponent, canActivate: [AuthGuard]},
  {path: Route.page, component: PageComponent, canActivate: [AuthGuard]},
  {path: Route.changePassword, component: ChangePasswordComponent, canActivate: [AuthGuard]},
  {path: Route.analytics, component: AnalyticsKeywordSearchComponent, canActivate: [AuthGuard]},
  {path: Route.organizations, component:SchoolComponent, canActivate: [AuthGuard]},
  {path: Route.addorganization, component:AddSchoolComponent, canActivate: [AuthGuard]},
  {path: Route.organizationdetail, component: EditSchoolComponent, canActivate: [AuthGuard]},
  {path: Route.deleteorganization, component: DeleteSchoolComponent, canActivate: [AuthGuard]},
  {path: Route.announcement, component: AnnouncementComponent , canActivate: [AuthGuard]},
  {path: Route.addannouncement, component: AddAnnouncementComponent , canActivate: [AuthGuard]},
  {path: Route.editannouncement, component: EditAnnouncementComponent , canActivate: [AuthGuard]},
  {path: Route.module, component: ModuleComponent , canActivate: [AuthGuard]},
  {path: Route.addmodule, component: AddModuleComponent , canActivate: [AuthGuard]},
  {path: Route.editmodule, component: EditModuleComponent , canActivate: [AuthGuard]},
  {path: Route.userrole, component: UserRoleComponent , canActivate: [AuthGuard]},
  {path: Route.adduserrole, component: AddUserRoleComponent , canActivate: [AuthGuard]},
  {path: Route.edituserrole, component: EditUserRoleComponent , canActivate: [AuthGuard]},
  {path: Route.editpermission, component: EditUserPermissionComponent , canActivate: [AuthGuard]},
  {path: Route.users, component: UsersPageComponent , canActivate: [AuthGuard]},
  {path: Route.schools, component: SchoolsComponent , canActivate: [AuthGuard]},
  {path: Route.teachers, component: TeachersComponent , canActivate: [AuthGuard]},
  {path: Route.students, component: StudentsComponent , canActivate: [AuthGuard]},
  {path: Route.addschools, component: AddSchoolsComponent , canActivate: [AuthGuard]},
  {path: Route.editschools, component: EditSchoolsComponent , canActivate: [AuthGuard]},
  {path: Route.deleteschools, component: DeleteSchoolsComponent, canActivate: [AuthGuard]},
  {path: Route.newsletter, component: NewsLetterComponent},
  // {path: Route.landingpage, component: LandingPageComponent},
  {path: '**', component: PageNotFoundComponent}
];
