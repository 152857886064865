
export class schools {
    id: number;
    schoolId: string;
    name: string;
    principleName: string;
    email: string;
    phone:number;
	  url:string;
    active:number;
    total_teachers: number;
    total_students: number;
    file_link: string;
  }
  