import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { NgxEditorModule } from 'ngx-editor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import {TitleCasePipe} from '@angular/common';
import {AnonymousAuthGuard, AppUserAuthGuard, AuthGuard} from './auth/auth.guard';
import {appRoutes} from './config/routes';
import {ReqInterceptor} from './utils/req-interceptor';
// services
import {FormService} from './services/form.service';
import {UserService} from './services/user.service';
// components
import {AppComponent} from './app.component';
import {ForgotPasswordComponent} from './components/user/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/user/reset-password/reset-password.component';
import {StoriesComponent} from './components/stories/stories.component';
import {HeaderComponent} from './components/common/header/header.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {SignInComponent} from './components/user/sign-in/sign-in.component';
import {PageNotFoundComponent} from './components/common/page-not-found/page-not-found.component';
import {MapsComponent} from './components/maps/maps.component';
import {DetailComponent} from './components/detail/detail.component';
import {TypographyComponent} from './components/typography/typography.component';
import {IconsComponent} from './components/icons/icons.component';
import {AddstoryComponent} from './components/addstory/addstory.component';
import {NotificationsComponent} from './components/notifications/notifications.component';
import {DeletestoryComponent} from './components/deletestory/deletestory.component';
import {UpgradeComponent} from './components/upgrade/upgrade.component';
import {PageComponent} from './components/page/page.component';
import {DataService} from './services/data.service';
import {NavbarModule} from './components/common/navbar/navbar.module';
import {SidebarModule} from './components/sidebar/sidebar.module';
import {LbdModule} from './components/lbd/lbd.module';
import {MatTableModule, MatSortModule, MatPaginatorModule, MatDialogModule, MatIconModule} from '@angular/material';
import {HomeComponent} from './components/home/home.component';
import { ChangePasswordComponent } from './components/user/change-password/change-password.component';
import { CategoryComponent } from './components/category/category.component';
import { AddCategoryComponent } from './components/add-category/add-category.component';
import { DeleteCategoryComponent } from './components/delete-category/delete-category.component';
import { EditCategoryComponent } from './components/edit-category/edit-category.component';
import { BtnDownloadUserDataComponent } from './components/btn-download-user-data/btn-download-user-data.component';
import { BtnDownloadSaleDataComponent } from './components/btn-download-sale-data/btn-download-sale-data.component';
import { BtnLoadModuleDataComponent } from './components/btn-load-module-data/btn-load-module-data.component';
import { BtnLoadUserRoleDataComponent } from './components/btn-load-userrole-data/btn-load-userrole-data.component';
import { BtnDownloadSchoolDataComponent } from './components/btn-download-school-data/btn-download-school-data.component';
import { AnalyticsKeywordSearchComponent } from './components/analytics-keyword-search/analytics-keyword-search.component';
import { SchoolComponent } from './components/school/school.component';
import { AddSchoolComponent } from './components/add-school/add-school.component';
import { EditSchoolComponent } from './components/edit-school/edit-school.component';
import { DeleteSchoolComponent } from './components/delete-school/delete-school.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { AddAnnouncementComponent } from './components/add-announcement/add-announcement.component';
import { EditAnnouncementComponent } from './components/edit-announcement/edit-announcement.component';
import { ModuleComponent } from './components/module/module.component';
import { AddModuleComponent } from './components/add-module/add-module.component';
import { EditModuleComponent } from './components/edit-module/edit-module.component';
import { UserRoleComponent } from './components/userrole/userrole.component';
import { AddUserRoleComponent } from './components/add-userrole/add-userrole.component';
import { EditUserRoleComponent } from './components/edit-userrole/edit-userrole.component';
import { EditUserPermissionComponent } from './components/edit-user-permission/edit-user-permission.component';
import { NewsLetterComponent } from './components/news-letter/news-letter.component';
import { UsersPageComponent } from './components/users-page/users-page.component';
import { SchoolsComponent } from './components/schools/schools.component';
import { TeachersComponent } from './components/teachers/teachers.component';
import { StudentsComponent } from './components/students/students.component';
import { AddSchoolsComponent } from './components/add-schools/add-schools.component';
import { EditSchoolsComponent } from './components/edit-schools/edit-schools.component';
import { DeleteSchoolsComponent } from './components/delete-schools/delete-schools.component';
import { BtnUploadSchoolDataComponent } from './components/btn-upload-school-data/btn-upload-school-data.component';
import { WizardConfirmSchoolDataComponent } from './components/wizard-confirm-school-data/wizard-confirm-school-data.component';
import { BtnCsvPreviewComponent } from './components/btn-csv-preview/btn-csv-preview.component';
import { NgxPayPalModule } from 'ngx-paypal';

export const MY_NATIVE_FORMATS = {
    fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
    datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
    timePickerInput: {hour: 'numeric', minute: 'numeric'},
    monthYearLabel: {year: 'numeric', month: 'numeric'},
    dateA11yLabel: {year: 'numeric', month: 'numeric', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'numeric'},
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SignInComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    StoriesComponent,
    PageNotFoundComponent,
    TypographyComponent,
    IconsComponent,
    MapsComponent,
    NotificationsComponent,
    UpgradeComponent,
    DetailComponent,
    AddstoryComponent,
    DeletestoryComponent,
    PageComponent,
    HomeComponent,
    ChangePasswordComponent,
    CategoryComponent,
    AddCategoryComponent,
    DeleteCategoryComponent,
    EditCategoryComponent,
    WizardConfirmSchoolDataComponent,
    BtnDownloadUserDataComponent,
	  BtnDownloadSaleDataComponent,
    BtnLoadModuleDataComponent,
    BtnLoadUserRoleDataComponent,
    BtnUploadSchoolDataComponent,
    BtnCsvPreviewComponent,
    BtnDownloadSchoolDataComponent,
    AnalyticsKeywordSearchComponent,
    SchoolComponent,
    AddSchoolComponent,
    EditSchoolComponent,
    DeleteSchoolComponent,
    LandingPageComponent,
	AnnouncementComponent,
	AddAnnouncementComponent,
	EditAnnouncementComponent,
  ModuleComponent,
	AddModuleComponent,
	EditModuleComponent,
  UserRoleComponent,
	AddUserRoleComponent,
	EditUserRoleComponent,
  EditUserPermissionComponent,
	NewsLetterComponent,
	UsersPageComponent,
  SchoolsComponent,
  TeachersComponent,
  StudentsComponent,
  AddSchoolsComponent,
  EditSchoolsComponent,
  DeleteSchoolsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    NavbarModule,
    SidebarModule,
    RouterModule,
    LbdModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSortModule,
	MatPaginatorModule,
  MatDialogModule,
  MatIconModule,
    NgxEditorModule,
    TooltipModule.forRoot(),
    AngularFontAwesomeModule,
    NgSelectModule,
    NgxPayPalModule,
	OwlDateTimeModule, 
	OwlNativeDateTimeModule
  ],
  providers: [
    AuthGuard,
    AnonymousAuthGuard,
    AppUserAuthGuard,
    TitleCasePipe,
    UserService,
    FormService,
    DataService,
	{provide: HTTP_INTERCEPTORS, useClass: ReqInterceptor, multi: true},
	{provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS},
  ],
  entryComponents: [WizardConfirmSchoolDataComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
