import { Component, OnInit, ViewChild} from '@angular/core';
import {Route, SERVER_IMAGE_PATH} from '../../utils/constant';
import {DataService} from '../../services/data.service';
import { MatSort, MatTableDataSource ,MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import {ServerResponse} from '../../models/server-response.model';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-school',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.css']
})


export class SchoolsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource;
  displayColumns = ['id','schoolId','name','principleName','email','phone','url', 'total_teachers', 'total_students', 'active', 'action'];
  appRouts = Route;
  searchFor: string;
  SERVER_IMAGE_PATH= SERVER_IMAGE_PATH;

  constructor(private data: DataService,private user: UserService, private router: Router, private http: HttpClient) {}
  
  ngOnInit() {
    this.data.getSchoolsList().subscribe(data => {
      if (data.error === 0 && data.authError === 0) {
        if (!data) {
          return;
        }
        this.dataSource = new MatTableDataSource(data.data.schoolList);
		this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else if (data.error === 1 && data.authError === 1) {
          localStorage.clear();
          this.router.navigate(['/' + Route.login]);
        }
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  removeFilter() {
    this.dataSource.filter = null;
    this.searchFor = '';
  }

  changeStatus(e, id) {
    console.log('id' , id);
    let state = e.target.checked ? 1 : 0;
    let message;
    if(state){
      message = "By activiting this School will activate all Teachers and Students Accounts";
    } else {
      message = "By deactiviting this School will deactivate all Teachers and Students Accounts";
    }

    swal({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      buttons: [
        "Cancel",
        "OK"
      ],
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.data.updateSchoolsStatusbyId({id: id, active: state}).subscribe((response: ServerResponse) => {
          console.log('response', response);
          if (response.error === 0 && response.authError === 0) {
            swal({
              title: 'Message',
              text: 'School State Updated Successfully',
              icon: 'success'
            });
          } else if (response.error > 0) {
            swal({
              title: 'Message',
              text: 'There is problem while updating state of a school',
              icon: 'success'
            });
          }
        });
      } else {
        console.log(willDelete);
        e.target.checked = !state;
      }
    });
  }

  downloadFile(fileUrl: string, event: Event) {
    event.preventDefault(); // Prevent the default anchor behavior

    this.http.get(fileUrl, { responseType: 'blob' }).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = ''; // Optional: you can specify the filename here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url); // Clean up the URL object
    }, error => {
      console.error('Download error:', error);
    });
  }
}
