import {environment} from '../../environments/environment';
import {Config} from '../config/app.config';

export const USER_DATA_FILE_PATH = environment.USER_DATA_FILE_PATH;
export const SERVER_IMAGE_PATH = environment.SERVER_IMAGE_PATH;

export const Api = {
  'baseUrl': Config.api,
  'login': Config.api + 'admin/login', // POST
  'landingpagelogin': Config.api + 'web/login', // POST
  'logout': Config.api + 'account/logout',
  'forgotPassword': Config.api + 'user/forgetPassword',
  'verifyToken': Config.api + 'user/verifyToken', // GET
  'resetPassword': Config.api + 'user/setPassword',
  'changePassword' : Config.api + 'account/password',
  'story': Config.api + 'story', // POST (create), PUT (update), DELETE,
  'storyList': Config.api + 'story/list',  // GET
  'organizationList': Config.api + 'organization/list',  // GET
  'announcement': Config.api + 'announcement', // POST (create), PUT (update), DELETE,
  'announcementList': Config.api + 'announcement/list', //GET
  'module': Config.api + 'module', // POST (create), PUT (update), DELETE,
  'moduleList': Config.api + 'module/list', //GET
  'moduleLoad': Config.api + 'module/loadModule', // PUT
  'moduleUserList': Config.api + 'module/user/list', //GET
  'resetUserPassword': Config.api + 'user/resetPassword', //POST

  'userModuleList': Config.api + 'module/user/list', //GET
  'userrole': Config.api + 'user/role', // POST (create), PUT (update), DELETE,
  'userroleList': Config.api + 'user/role/list', //GET
  'userroleUser': Config.api + 'user/role/user', //PUT
  'userRoleLoad': Config.api + 'user/role/loadRole', // PUT

  'category': Config.api + 'category', // POST (create), PUT (update), DELETE,
  'organization': Config.api + 'organization', // POST (create), PUT (update), DELETE,
  'signup': Config.api + 'web/signup', // POST (create)
  'recipt': Config.api + 'web/subscription', // POST (create)
  'landingPage': Config.api + 'organization/landing', // POST (create)
  'categoryList': Config.api + 'category/list',  // GET
  'publishStory': Config.api + 'story/publish',
  'postFile': Config.api + 'upload/file',
  'tutorial': Config.api + 'tutorial',
  'termsAndConditions': Config.api + 'eula',
  'downloadUsersData': Config.api + 'user/data/file', // GET
  'downloadSalesData': Config.api + 'cron/organization/sale/report', // GET
  'analyticSearch': Config.api + 'app/search', // GET , ?days=3
  'publishCategory': Config.api + 'category/publish', //Publish Category
  
  'userList': Config.api + 'user/list', //GET
  'actCancelUserSub': Config.api + 'user/subscription/activeCancel', //PUT,DELETE
  'extendUserSub': Config.api + 'user/subscription/extDate', //PUT
  'user': Config.api + 'user', //  GET,DELETE,PUT
  'schoolUploadFile': Config.api + 'school/uploadDataFile', //  POST
  'schoolsList': Config.api + 'school/list',  // GET
  'teachersList': Config.api + 'school/teacher/list',  // GET
  'studentsList': Config.api + 'school/student/list',  // GET
  'schools': Config.api + 'school', // POST (create), PUT (update), DELETE,
  'updateSchoolsState': Config.api + 'school/updateState' //PATCH (update)

};

export enum Route {
  default = 'mike',
  login = 'mike/admin',
  landingpagelogin = 'landingpagelogin',
  langingpageDetail ='langingpageDetail',
  forgotPassword = 'forgot-password',
  resetPassword = 'set-password',
  changePassword = 'change-password',
  stories = 'stories',
  home = 'home',
  typography = 'typography',
  categories = 'category',

  schools = 'schools',
  addschools = 'addschools',
  editschools = 'schoolsdetail/:id',
  deleteschools = 'deleteschools/:id',
  teachers = 'scls/teachers',
  students = 'scls/students',
  
  icons = 'icons',
  maps = 'maps',
  notifications = 'notifications',
  upgrade = 'upgrade',
  detail = 'detail/:id',
  addstory = 'addstory',
  addcategory = 'addcategory',
  deletecategory = 'deletecategory/:id',
  categorydetail = 'categorydetail/:id',
  
  users = 'users',
  newsletter = 'deepLink.php',
  announcement='announcement',
  addannouncement='addannouncement',
  editannouncement='editannouncement/:id',
  
  module='module',
  addmodule='addmodule',
  editmodule='editmodule/:id',
  userrole='userrole',
  adduserrole='adduserrole',
  edituserrole='edituserrole/:id',
  editpermission='editpermission/:id',

  deletestory = 'deletestory/:id',
  page = 'page',
  analytics="analytics",
  organizations ="organizations",
  landingpage =":title",
  addorganization ="addorganization",
  organizationdetail = 'organizationdetail/:id',
  deleteorganization = 'deleteorganization/:id',
}

export enum Token {
  userToken = 'userToken',
  webuserToken = 'webuserToken',
  userClaims = 'userClaims'
}
